.home {
	width: 65%;
	display: flex;
	align-content: center;

	h1 {
		font-size: 5rem;
		margin-bottom: 2.5rem;
	}

	p {
		font-size: 20px;
		margin-bottom: 1.5rem;
	}

	.link {
		display: flex;
		margin-bottom: 1rem;

		.app-icon {
			width: 40px;
			height: 40px;
			border-radius: 100px;
			margin-right: 0.5rem;
			pointer-events: none;
			user-select: none;
		}

		.hover-wrapper {
			display: flex;
			flex-direction: row;

			.app-link {
				margin-left: 1rem;
				align-self: center;
				text-decoration: none;
			}
	
			.arrow {
				height: 20px;
				width: 20px;
				margin-left: 0.5rem;
				align-self: center;
				cursor: pointer;
			}
		}

		.hover-wrapper:hover {
			transform: scale(1.1);
		}
	}


	.right {
		display: flex;
		justify-content: center;

		.mockup {
			width: 28rem;
			height: 30rem;
			pointer-events: none;
			user-select: none;
		}
	}
}

@media only screen and (max-width: 1500px) {
	.home {
		width: 80%;
	}
}

@media only screen and (max-width: 1060px) {
	.home {
		width: 100%;
	}
}

@media only screen and (max-width: 991px) {
	.home {
		padding: 0 1rem;

		.right {
			margin: 5rem 0;
		}
	}
}

@media only screen and (max-width: 767px) {
	.home {
		h1 {
			font-size: 3rem;
		}

		.right {
			.mockup {
				width: 20rem;
				height: 23rem;
			}
		}
	}
}

@media only screen and (max-width: 415px) {
	.home {
		.right {
			.mockup {
				width: 16rem;
				height: 19rem;
			}
		}
	}
}