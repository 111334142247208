.digiwallet {
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 0;

	h3 {
		color: #1DA1F2 !important;
	}

	.landing {
		width: 65%;
		min-height: calc(100vh - 170px) !important;
		margin-bottom: 6rem;

		.left {
			margin-top: 5rem;

			.app-title {
				display: flex;
				flex-direction: row;
				align-items: center;
				margin-bottom: 2rem;

				img {
					width: 80px;
					height: 80px;
					border-radius: 20px;
					margin-right: 1rem;
					pointer-events: none;
					user-select: none;
				}
	
				h1 {
					font-size: 5rem;
					margin: 0;
				}
			}
	
			p {
				font-size: 20px;
				margin-bottom: 4rem;
			}

			.badges {
				display: flex;
		
				.androidBadge {
					width: 250px;
					height: 99px;
					display: inline-block;
					pointer-events: none;
					user-select: none;
				}

				.iosBadge {
					display: inline-block;
					width: 200px;
					height: 67px;
					margin-top: 1rem;
					border-radius: 13px;
					pointer-events: none;
					user-select: none;
				}
			}
		}

		.landing-img {
			height: 34rem;
			width: 28rem;
			pointer-events: none;
			user-select: none;
		}
	}

	@media only screen and (max-width: 1110px) {
		.landing {
			.right {
				.landing-img {
					height: 29rem;
					width: 24rem;
				}
			}
		}
	}

	@media only screen and (max-width: 767px) {
		.landing {
			.left {
				margin-top: 0;
	
				.app-title {
					img {
						width: 50px;
						height: 50px;
						border-radius: 15px;
					}
	
					h1 {
						font-size: 3rem;
					}
				}
	
				p {
					margin: 0;
				}

				.badges {
					display: contents;

					.iosBadge {
						width: 150px;
						height: 83px;
						border-radius: 13px;
						margin-left: 0.7rem;
					}
	
					.androidBadge {
						width: 175px;
						height: 75px;
						margin-top: 1.2rem;
					}
				}
			}

			.right {
				display: flex;
				justify-content: center;
	
				.landing-img {
					height: 19rem;
					width: 15rem;
					margin-top: 2rem;
				}
			}
		}
	}

	@media only screen and (max-width: 361px) {
		.landing {
			.left {
				.badges {
					.androidBadge {
						margin-top: 0;
					}
				}
			}
		}
	}

	.about {
		width: 80%;
		min-height: fit-content;
		margin-bottom: 12rem;

		.left, .right {
			display: flex;
			flex-direction: column;
			justify-content: center;

			h3 {
				margin: 0 0 4rem 0;
			}
		}

		img {
			height: 22rem;
			width: 45rem;
			pointer-events: none;
			user-select: none;
		}
	}

	@media only screen and (max-width: 1290px) {
		.about {
			.left {
				img {
					height: 18rem;
					width: 38rem;
				}
			}
		}
	}

	@media only screen and (max-width: 950px) {
		.about {
			.left {
				img {
					height: 13rem;
					width: 28rem;
				}
			}
		}
	}


	@media only screen and (max-width: 767px) {
		.about {
			min-height: fit-content;

			.left {
				display: none;
			}
		}
	}

	.features {
		width: 70%;
		min-height: fit-content;
		margin-bottom: 10rem;

		h3 {
			margin: 0 0 4rem 0;
		}

		.right {
			img {
				height: 34rem;
				width: 30rem;
				pointer-events: none;
				user-select: none;
			}
		}

		@media only screen and (max-width: 767px) {
			.right {
				display: flex;
				justify-content: center;

				img {
					height: 21rem;
					width: 19rem;
					margin-top: 2rem;
				}
			}
		}
	}

	@media only screen and (max-width: 1290px) {
		.features {
			.right {
				img {
					height: 26rem;
					width: 22rem;
				}
			}
		}
	}

	@media only screen and (max-width: 950px) {
		.features {
			.right {
				img {
					height: 22rem;
					width: 19rem;
				}
			}
		}
	}
}

@media only screen and (max-width: 1500px) {
	.digiwallet {
		.landing, .features {
			width: 80%;
		}

		.about {
			width: 90%
		}
	}
}

@media only screen and (max-width: 1000px) {
	.digiwallet {
		.landing, .about, .features {
			width: 100%;
			margin-bottom: 4rem;

			h3 {
				margin-bottom: 2rem !important;
			}
		}
	}
}