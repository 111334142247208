.footer {
	width: 100%;
	padding: 4rem;

	.left {
		display: flex;
		align-items: center;

		.badges {
			.androidBadge {
				width: 183px;
				height: 71px;
				pointer-events: none;
				user-select: none;
			}
			
			.iosBadge {
				width: 150px;
				height: 48px;
				border-radius: 13px;
				pointer-events: none;
				user-select: none;
			}
		}
	}

	.right {
		display: flex;
		flex-direction: row;
		justify-content: flex-end;
		align-items: center;

		.kofi {
			border: 0px;
			height: 36px;
			margin-left: 2rem;
			pointer-events: none;
			user-select: none;
		}

		.links {
			display: flex;
			flex-direction: column;

			a {
				text-decoration: none;
			}
		}

	}

	@media only screen and (max-width: 841px) {
		.left {
			.badges {
				.iosBadge {
					margin-left: 0.5rem;
				}
			}
		}
	}

	@media only screen and (max-width: 767px) {
		.left {
			margin-bottom: 1rem;

			.badges {
				.iosBadge {
					margin-left: 0;
				}
			}
		}

		.right {
			justify-content: flex-start;
		}
	}
}

@media only screen and (max-width: 485px) {
	.footer {
		padding: 1rem;

		.left {
			display: flex;
			flex-direction: column;
			justify-content: center;
			
			.badges {
				display: contents;
			}
		}
	
		.right {
			flex-direction: column;
	
			.kofi {
				margin-top: 2rem;
				margin-right: 2rem;
			}
		}
	}
}