.contact {
	width: 50%;
	margin-bottom: 4rem;

	.contact-content {
		margin: 0;
		background-color: rgb(20, 20, 20);
		border-radius: 10px;
		padding: 3rem;
		width: 100%;

		.left {
			.success {
				color: rgb(27, 168, 27) !important;
			}

			.error {
				color: rgb(196, 76, 76) !important;
			}

			.form-floating {
				max-width: 500px;
			}
		
			.form-control:focus {
				box-shadow: 0 0 0 0.25rem rgba(105, 105, 105, 0.3);
			}
		
			input, input:focus {
				background-color: transparent;
				color: rgb(236, 236, 236);
				border-radius: 10px;
				border-color: rgb(150, 150, 150);
			}
		
			textarea, textarea:focus {
				min-height: 8rem;
				background-color: transparent;
				color: rgb(236, 236, 236) !important;
				border-radius: 10px;
				border-color: rgb(150, 150, 150);
			}
		
			button {
				padding: 0.2rem 2rem;
			}
		}

		.right {
			.social-wrapper {
				display: flex;
				flex-direction: row;
				align-content: center;
				margin-bottom: 2rem;

				a {
					text-decoration: none;
					text-align: center;
				}

				img {
					width: 20px;
					height: 20px;
					margin-right: 1rem;
				}

				a:hover {
					cursor: pointer;
					transform: scale(1.1);
				}
			}
		}
	}
}

@media only screen and (max-width: 1500px) {
	.contact {
		width: 70%;
	}
}

@media only screen and (max-width: 1100px) {
	.contact {
		width: 80%;
		padding: 0 1rem;
	}
}

@media only screen and (max-width: 960px) {
	.contact {
		width: 90%;
	}
}

@media only screen and (max-width: 855px) {
	.contact {
		width: 100%;
	}
}

@media only screen and (max-width: 767px) {
	.contact {
		.left {
			margin-bottom: 5rem;
		}

		.right {
			display: flex;
			flex-direction: row;

			.social-wrapper {
				margin: 0 0 0 1rem !important;

				img {
					margin-right: 0.5rem !important;
				}
			}

			.social-wrapper:nth-child(1) {
				margin: 0 !important;
			}
		}
	}
}

@media only screen and (max-width: 700px) {
	.contact {
		.contact-content {
			padding: 1rem;
		}
	}
}

@media only screen and (max-width: 487px) {
	.contact {
		.right {
			flex-direction: column !important;

			.social-wrapper {
				margin: 0 0 2rem 0 !important;

				img {
					margin-right: 1rem !important;
				}
			}

			.social-wrapper:nth-child(1) {
				margin: 0 0 2rem 0 !important;
			}
		}
	}
}