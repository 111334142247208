
.reviews {
	width: 100%;
	min-height: fit-content;
	padding: 6rem 4rem;

	.heading {
		text-align: center;
		margin-bottom: 6rem;
		color: white !important;
	}

	.col-md-4 {
		display: flex;
		justify-content: center;
	}

	.review-box {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		height: 18rem;
		width: 15rem;
		background-color: rgba(255, 255, 255, 0.8);
		border-radius: 25px;
		padding: 2rem;
		overflow: scroll;
		box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);

		.user {
			color: black !important;
			font-weight: 500;
			text-align: center;
		}

		.comment {
			color: black !important;
			text-align: center;
		}

		.star-wrapper {
			display: flex;
			justify-content: center;

			.star {
				width: 20px;
				height: 20px;
			}
		}
	}

	.no-reviews {
		text-align: center;
		font-size: 18px;
	}

	.suggestion {
		margin-top: 2rem;
		text-align: center;

		.ios-link {
			margin-right: 1rem;
		}
	}
}

@media only screen and (max-width: 767px) {
	.base {
		padding: 2rem;

		h3 {
			margin-bottom: 3rem;
		}
	}
}

.weatherly-rev {
	background: linear-gradient(to bottom, transparent, #080808b6), tomato;
}

.digiwallet-rev {
	background: linear-gradient(to bottom, transparent, #080808b6), #1DA1F2;
}