.weatherly {
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 0;

	h3 {
		color: tomato !important;
	}

	.landing {
		width: 65%;
		min-height: calc(100vh - 170px) !important;

		.left {
			margin-top: 5rem;

			.app-title {
				display: flex;
				flex-direction: row;
				align-items: center;
				margin-bottom: 2rem;

				img {
					width: 80px;
					height: 80px;
					border-radius: 20px;
					margin-right: 1rem;
					pointer-events: none;
					user-select: none;
				}

				h1 {
					font-size: 5rem;
					margin: 0;
				}
			}

			p {
				font-size: 20px;
				margin-bottom: 4rem;
			}

			.badges {
				display: flex;

				.androidBadge {
					width: 250px;
					height: 99px;
					display: inline-block;
					pointer-events: none;
					user-select: none;
				}

				.iosBadge {
					display: inline-block;
					width: 200px;
					height: 67px;
					margin-top: 1rem;
					border-radius: 13px;
					pointer-events: none;
					user-select: none;
				}
			}
		}

		.right {
			display: flex;
			justify-content: center;

			.landing-img {
				height: 35rem;
				width: 35rem;
				pointer-events: none;
				user-select: none;
			}
		}

		@media only screen and (max-width: 767px) {
			.left {
				margin-top: 0;

				.app-title {
					img {
						width: 50px;
						height: 50px;
						border-radius: 15px;
					}

					h1 {
						font-size: 3rem !important;
					}
				}

				p {
					margin: 0;
				}

				.badges {
					display: contents;

					.iosBadge {
						width: 150px;
						height: 83px;
						border-radius: 13px;
						margin-left: 0.7rem;
					}

					.androidBadge {
						width: 175px;
						height: 75px;
						margin-top: 1.2rem;
					}
				}
			}

			.right {
				display: flex;
				justify-content: center;

				.landing-img {
					height: 25rem;
					width: 25rem;
					margin-top: 2rem;
				}
			}
		}

		@media only screen and (max-width: 361px) {
			.left {
				.badges {
					.androidBadge {
						margin-top: 0;
					}
				}
			}
		}
	}

	.about {
		width: 70%;
		min-height: fit-content;
		margin-bottom: 10rem;

		.right {
			display: flex;
			flex-direction: column;
			justify-content: center;

			h3 {
				margin-bottom: 4rem;
			}
		}

		img {
			height: 43rem;
			width: 33rem;
			pointer-events: none;
			user-select: none;
		}

		@media only screen and (max-width: 1060px) {
			img {
				height: 35rem;
				width: 26rem;
			}
		}
	}

	@media only screen and (max-width: 767px) {
		.about {
			min-height: fit-content !important;

			.right {
				h3 {
					margin: 0;
				}
			}

			.col-img {
				display: none;
			}
		}
	}

	.features {
		width: 70%;
		min-height: fit-content;
		margin-bottom: 10rem;

		h3 {
			margin-bottom: 4rem;
		}

		.strike {
			text-decoration: line-through;
		}

		.premium-notice {
			margin-top: 2rem;
			color: tomato !important;
		}

		.img-col {
			display: flex;
			justify-content: center;

			img {
				height: 40rem;
				width: 19.5rem;
				pointer-events: none;
				user-select: none;
			}
		}
	}

	@media only screen and (max-width: 767px) {
		.features {
			.img-col {
				display: none;
			}
		}
	}

	.api {
		width: 65%;
		min-height: fit-content;
		display: flex;
		flex-direction: column;
		text-align: center;
		margin-bottom: 10rem;

		h3 {
			margin-bottom: 4rem;
		}
	}
}

@media only screen and (max-width: 1500px) {
	.weatherly {
		.landing,
		.features,
		.api {
			width: 80%;
		}

		.about {
			width: 90%;
		}
	}
}

@media only screen and (max-width: 1000px) {
	.weatherly {
		.landing,
		.about,
		.features,
		.api {
			width: 100%;
			margin-bottom: 4rem;

			h3 {
				margin-bottom: 2rem !important;
			}
		}
	}
}
